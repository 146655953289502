import React from "react"
import * as styles from './Footer.module.scss';
import logo from '../../images/branding/sfs-primary-rev.svg';
import { Mail } from 'react-feather';
import cx from 'classnames';

const Footer = ({ setShowForm }) => {
    return (
        <div className={styles.footer}>
            <div className="container">
                <div className="row">
                    <div className={cx('col-md-9', 'mx-auto', styles.contactLayout)}>
                        <div className={styles.footerLogo}>
                            <img src={logo} alt='Scientific Financial Systems Inc.' />
                        </div>
                        <div className={styles.contactLinks}>
                            <address className={cx(styles.contactLinksAddress)}>
                                100 High Street, 11th Floor<br />
                                Boston, MA 02110
                            </address>
                            <a href="mailto:sales@scifinsys.com"><Mail size="22" /> sales@scifinsys.com</a>
                        </div>
                    </div>
                </div>
                <p className={styles.copyright}>© 2021 Scientific Financial Systems Inc</p>
            </div>
        </div>
    )
}

export default Footer