import React from 'react';
import { X } from 'react-feather';

const Modal = ({ children, closeModal }) => {
    return (
        <div
            className='modal'
            role="button"
            aria-label="Close modal"
            onClick={(event) => {
                if (event.target.classList.contains('modalBlanker')) {
                    closeModal();
                }
            }}
        >
            <div className='modalBlanker'>
                <div className="modalContent">
                    <button type='button' className='modalCloseButton' onClick={() => closeModal()}>
                        <X size="24" role="button"
                            aria-label="Close modal" />
                    </button>
                    <div className="modalContentHolder">
                        <div className='modalContentInner'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
