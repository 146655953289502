import React, { useState, useRef } from "react"
import axios from "axios"
import { useForm } from "react-hook-form"
import { CheckCircle, AlertCircle } from 'react-feather';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_SITE_KEY, FORMSPREE_URL } from '../../utils/constants'
import { navigate } from "gatsby"

const DemoForm = () => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const [captchaValue, setCaptchaValue] = useState();
    const captchaRef = useRef(null);

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    const handleServerResponse = (ok, msg) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            reset();
        }
        setCaptchaValue(null);
        captchaRef && captchaRef.current && captchaRef.current.reset();
    };

    const onSubmit = ({ name, email, message, company }) => {
        setServerState({ submitting: true });
        const formData = new FormData();
        formData.append("name", name);
        formData.append("company", company);
        formData.append("email", email);
        formData.append("message", message);
        formData.append('g-recaptcha-response', captchaValue);
        formData.append('source', 'scifinsys.com')
        axios({
            method: "post",
            url: FORMSPREE_URL,
            data: formData
        }).then(_ => {
            navigate('/thanks');
            //handleServerResponse(true, "Thanks, We'll be in touch soon");
        }).catch(r => {
            handleServerResponse(false, "Oops, there was an error sending your message: " + r.response.data.error);
        });
    };

    const handleCaptchaChange = value => {
        setCaptchaValue(value);
    }

    return (
        <>
            <h3 className='uppercase'>Request a demo</h3>
            {serverState.status && (
                <p className={serverState.status.ok ? "successMsg" : "errorMsg"}>
                    {serverState.status.ok ? <CheckCircle /> : <AlertCircle />}
                    {serverState.status.msg}
                </p>
            )}

            {(!serverState.status || (serverState.status && !serverState.status.ok)) && (
                <form onSubmit={handleSubmit(onSubmit)} className='mb-1'>
                    <div className="basic-form__group">
                        <input
                            className="basic-form__text-box"
                            name="name"
                            {...register('name', { required: true })}
                            type="text"
                            placeholder="Your Name*"
                        />
                    </div>
                    {errors.name && <p className='basic-form__hint'>Please enter your name</p>}

                    <div className="basic-form__group">
                        <input
                            className="basic-form__text-box"
                            name="company"
                            {...register('company', { required: true })}
                            type="text"
                            placeholder="Company Name*"
                        />
                    </div>
                    {errors.company && <p className='basic-form__hint'>Please enter company name</p>}

                    <div className="basic-form__group">
                        <input
                            className="basic-form__text-box"
                            name="email"
                            {...register('email', { required: true })}
                            type="email"
                            placeholder="Your Email*"
                        />
                    </div>
                    {errors.email && <p className='basic-form__hint'>Please enter your email</p>}

                    <div className="basic-form__group">
                        <textarea
                            name="message"
                            className="basic-form__text-box"
                            {...register('message')}
                            placeholder="Add an optional message"
                            rows="4"
                        ></textarea>
                    </div>

                    <div className="mb-2">
                        <ReCAPTCHA
                            ref={r => captchaRef.current = r}
                            sitekey={GOOGLE_SITE_KEY}
                            onChange={(e) => handleCaptchaChange(e)}
                        />
                    </div>
                    <button type="submit" disabled={serverState.submitting || !captchaValue} className='btn'>{serverState.submitting ? 'Sending' : 'Send Demo Request'}</button>
                </form>
            )}
        </>
    )
}

export default DemoForm