import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as styles from './Header.module.scss';
import logo from '../../images/branding/sfs-primary.svg';
import { X, Menu } from 'react-feather'
import cx from 'classnames';

const Header = ({ siteTitle, setShowForm, position }) => {

    const [menuOpen, setMenuOpen] = useState(false);

    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: `${styles.mastheadNavLink} ${styles.mastheadNavLinkActive}` } : { className: styles.mastheadNavLink }
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: `${styles.mastheadNavLink} ${styles.mastheadNavLinkActive}` } : { className: styles.mastheadNavLink }
    }

    useEffect(() => {
        if (!menuOpen) {
            document.body.classList.remove('preventScroll');
        }
    }, [menuOpen])

    const toggleMenu = () => {
        if (!menuOpen) {
            setMenuOpen(true);
            setTimeout(() => document.body.classList.add('preventScroll'), 500)
        } else {
            setMenuOpen(false);
            setTimeout(() => document.body.classList.remove('preventScroll'), 500)
        }
    }


    return (
        <div className={cx(styles.mastheadWrapper, { [styles.mastheadWrapperScrolled]: position && position > 100 })}>
            <div className={cx(styles.masthead, 'container')}>
                <div className={styles.mastheadLogoLayout}>
                    <img src={logo} alt={siteTitle || 'SFS Logo'} className={cx(styles.mastheadLogo)} />
                </div>
                <div className={styles.mastheadToggle}>
                    <Menu onClick={() => toggleMenu()} />
                </div>
                <div className={cx(styles.mastheadContent, { [styles.mastheadContentVisible]: menuOpen })}>
                    <span className={styles.mastheadCloseNav} onClick={() => toggleMenu()}><X size="28" /></span>
                    <ul className={styles.mastheadNav}>
                        <li><Link getProps={isActive} to='/'>Home</Link></li>
                        <li><Link getProps={isPartiallyActive} to='/solutions'>Solutions</Link></li>
                        <div className={styles.mastheadAboutUsDropdown}><li className={styles.mastheadAboutUsDropdownList}><Link getProps={isPartiallyActive} to='/story'>About Us</Link></li>
				<div className={styles.mastheadAboutUsDropdownContent}><li><Link getProps={isPartiallyActive} to='/story'>Our Story</Link></li>
				<li><Link getProps={isPartiallyActive} to='/team'>Team</Link></li>
				</div>
			</div>
                        <li><button className={styles.mastheadNavLink} onClick={setShowForm}>Request Demo</button></li>
                        <li><Link getProps={isPartiallyActive} to='/blog'>Blog</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}


Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
