/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowScroll } from 'react-use';

import Header from "./Header"
import Footer from "./Footer"
import Modal from './Modal'
import DemoForm from './DemoForm';

import "../styles/main.scss"


const Layout = ({ children, showDemo }) => {

  const { y } = useWindowScroll();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [showForm, setShowForm] = useState(showDemo);

  useEffect(() => {
    if (showDemo !== 'undefined') {
      setShowForm(showDemo);
    }
  }, [showDemo, setShowForm]);

  return (
    <div className='page'>
      <div className="page-content">
        <Header position={y} setShowForm={setShowForm} siteTitle={data.site.siteMetadata?.title || `Title`} />
        {showForm && (
          <Modal closeModal={() => setShowForm(false)}>
            <DemoForm />
          </Modal>
        )}
        <main>{children}</main>
      </div>
      <Footer setShowForm={setShowForm} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout